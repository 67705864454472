import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faDatabase, faBug, faFlag, faLightbulb, faPaw, faWrench, faQuestion, faBoxOpen, faMale, faStar, faUser, faExclamationTriangle, faBan, faMinusCircle, faCheck, faUserCheck, faTimes, faGavel, faGift, faTshirt, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faMoneyBillAlt, faCommentDots } from '@fortawesome/free-regular-svg-icons';

import FactionIcon from './FactionIcon';
import TagTooltip from './TagTooltip';
import utils from '../util/utils';
import './Tag.scss';

const sourceicons = {
    achievement: faShieldAlt,
    currency: faDatabase,
    discovery: faLightbulb,
    drop: faBug,
    faction: faFlag,
    other: farCircle,
    petbattle: faPaw,
    profession: faWrench,
    promotion: faMoneyBillAlt,
    quest: faQuestion,
    treasure: faBoxOpen,
    vendor: faMale
};

function round(num) {
    if (0 < num && num < 1) return '<1';
    return utils.toFixed(num, 0);
}

function icon(item) {
    if (item.granted) return faGift;
    if (item.uploaded) return faFileUpload;
    if (item.character) return faCheck
    return item.account ? faUserCheck : faTimes;
}

function Source(props) {
    const item = props.item;
    return (
        <div>
            <FontAwesomeIcon icon={item.sourceicon ? sourceicons[item.sourceicon] : sourceicons['other']} className="mr-1" />
            {item.source}
            {item.sourcestanding ? ' (' + item.sourcestanding + ')' : null}
            {item.sourcezone ? ' (' + item.sourcezone + ')' : null}
        </div>
    );
}

export default class Tag extends Component {
    render() {
        const item = this.props.item;

        const showCollected = this.props.showCollected;
        const showRarity = this.props.showRarity !== false;

        let content = (
            <>
                <div className="position-relative">
                    {showCollected ? <div className={'badge position-absolute ' + (item.character || item.account ? 'badge-collected' : 'badge-uncollected')}><div className="small"><FontAwesomeIcon icon={icon(item)} /></div></div> : null}
                    {(typeof item.level === 'number') ? <div className={'badge position-absolute badge-quality-' + (item.quality >= 0 ? item.quality : 3)}>{item.level}{item.maxlevel ? '/' + item.maxlevel : ''}</div> : null}
                    <img className="rounded m-2 d-inline-block item" src={utils.icon(item.icon, item.displayid)} alt="" />
                </div>
                <div className="my-auto">
                    <div className={item.textClass + (item.quality >= 0 ? ' disabled-quality-' + item.quality : '')}>
                        {item.name}
                    </div>
                    <div className="d-flex small">
                        {(typeof item.points === 'number') ? <div className="mr-1"><FontAwesomeIcon icon={item.points ? faShieldAlt : faStar} />{item.points || null}</div> : null}
                        {item.source ? <Source item={item} /> : null}
                        {item.category ? <div>{item.category}{item.subcategory ? ': ' : null}{item.subcategory}</div> : null}
                    </div>
                </div>
            </>
        );

        const link = item.link || utils.wowhead(item.field, item.wowheadid, item.id);
        if (link) {
            if (link.startsWith('/')) {
                content = <Link to={link} className="d-flex flex-fill text-truncate">{content}</Link>;
            } else {
                content = <a href={link} target="_blank" rel="noopener noreferrer" className="d-flex flex-fill text-truncate">{content}</a>;
            }
        } else {
            content = <div className="d-flex flex-fill text-truncate">{content}</div>;
        }

        return (
            <div className="p-0 item">
                <div className={'d-flex rounded pr-1 ' + ((!showCollected || item.character || item.account) ? 'collected' : 'uncollected')}>
                    {content}
                    <TagTooltip item={this.props.item} showDetails={this.props.showDetails} onCollectedBy={this.props.onCollectedBy}>
                        <div className="my-auto">
                            <div className="d-flex">
                                <div className="ml-auto">{showRarity ? <span className="badge"><FontAwesomeIcon icon={faUser} className="mr-1" />{(typeof item.rarity === 'number') ? <>{round(item.rarity)}%</> : '?%'}</span> : <span className="badge">&nbsp;</span>}</div>
                                {item.altLevel ? <div className="ml-auto text-nowrap"><span className="badge">{item.altLevel} <FontAwesomeIcon icon={faGavel} /> {item.altItemLevel}</span></div> : null}
                            </div>
                            <div className="d-flex">
                                <div className="ml-auto"><span className="badge">&nbsp;</span></div>
                                {item.note ? <div className="ml-1 text-nowrap"><span className="badge badge-primary"><FontAwesomeIcon icon={faCommentDots} /></span></div> : null}
                                {item.unobtainable ? <div className="ml-1 text-nowrap"><span className={'badge badge-un-' + item.unobtainable}><FontAwesomeIcon icon={faBan} /></span></div> : null}
                                {item.missing ? <div className="ml-1 text-nowrap"><span className="badge badge-1"><FontAwesomeIcon icon={faExclamationTriangle} /></span></div> : null}
                                {item.excluded ? <div className="ml-1 text-nowrap"><span className="badge badge-1"><FontAwesomeIcon icon={faMinusCircle} /></span></div> : null}
                                {item.faction < 2 ? <div className="ml-1 text-center"><span><FactionIcon faction={item.faction} /></span></div> : null}
                                {item.badge ? <div className="ml-1 text-nowrap"><span className="badge badge-primary"><FontAwesomeIcon icon={item.badge} /></span></div> : null}
                                {item.shared?.length > 1 ? <div className="ml-1 text-nowrap"><span className="badge badge-primary">+{item.shared.length-1}<FontAwesomeIcon icon={faTshirt} className="ml-1" /></span></div> : null}
                                {this.props.removeLink ? <div className="ml-1 text-nowrap"><span className="badge"><Link to={this.props.removeLink}><FontAwesomeIcon icon={faTimes} /></Link></span></div> : null}
                            </div>
                        </div>
                    </TagTooltip>
                </div>
            </div>
        );
    }
}