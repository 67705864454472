import React, { Component } from 'react';

import Section from './components/Section';
import artwork from './images/artwork.jpg';

class Brand extends Component {
    render() {
        document.title = 'Logos | Data for Azeroth | World of Warcraft Leaderboards for Collectors';

        return (
            <>
            <Section title="Logos">
                <p>
                    I always appreciate when the community shares <i>Data for Azeroth</i> with others.
                    If you would like to add a link to <i>Data for Azeroth</i> on your site,
                    here are some logos you can use. I
                    plan to add more variations in the future. Thank you!
                </p>
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                    <div className="m-5"><img src="/icon192.png" alt="Logo" /></div>
                    <div>
                        <div className="text-center"><a href="/icon32.png" target="external">(32x32 PNG)</a></div>
                        <div className="text-center"><a href="/icon192.png" target="external">(192x192 PNG)</a></div>
                        <div className="text-center"><a href="/icon256.png" target="external">(256x256 PNG)</a></div>
                        <div className="text-center"><a href="/icon384.png" target="external">(384x384 PNG)</a></div>
                        <div className="text-center"><a href="/icon512.png" target="external">(512x512 PNG)</a></div>
                    </div>
                </div>
            </Section>
            <Section title="Art">
                <p>
                    Thank you to <a href="https://bsky.app/profile/theluckyblackchat.bsky.social" target="external">Lucky Black Chat</a> for this amazing artwork!
                </p>
                <div className="d-flex justify-content-center">
                    <div><img src={artwork} alt="Artwork" style={{ maxHeight: '90vh' }} /></div>
                </div>
            </Section>
            </>
        );
    }
}

export default Brand;